<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <h1 class="mt-4 font-weight-bolder"><i class="fas fa-plus mr-3"></i>Create New Testimony</h1>
                <hr>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto">
                        <form @submit.prevent="postTestimony" class="row p-2">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="row p-auto">
                                    <div class="col-xl-12">
                                        <div class="avatar-upload"  v-show="!edit">
                                            <div class="text-center p-2">
                                                <label for="avatar">
                                                    <img width="25%" :src="files.length ? files[0].url : '/images/image-upload.png'" />
                                                </label>
                                            </div>
                                            <div class="text-center p-2">
                                                <file-upload
                                                extensions="jpg,jpeg,png"
                                                accept="image/png,image/jpeg,image/jpg"
                                                name="avatar"
                                                class="btn btn-current"
                                                :drop="!edit"
                                                v-model="files"
                                                @input-filter="inputFilter"
                                                @input-file="inputFile"
                                                ref="upload" required>
                                                Upload Photo
                                                </file-upload>
                                            </div>
                                        </div>

                                        <div class="avatar-edit" v-show="files.length && edit">
                                            <div class="avatar-edit-image" v-if="files.length">
                                                <cropper
                                                    class="cropper"
                                                    ref="cropper"
                                                    :src="files[0].url"
                                                    :stencil-props="{
                                                        handlers: {},
                                                        movable: false,
                                                        scalable: false,
                                                        aspectRatio: 1,
                                                    }"
                                                    :resize-image="{
                                                        adjustStencil: false
                                                    }"
                                                    image-restriction="stencil"
                                                ></cropper>
                                            </div>
                                            <div class="text-center p-4">
                                                <button type="button" class="btn btn-default" @click.prevent="$refs.upload.clear">Cancel</button>
                                                <button type="submit" class="btn btn-current" @click.prevent="editSave">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row p-auto">
                                    <div class="col-lg-12 mt-4">
                                        <div class="row">
                                            <div class="form-group col-md-12">
                                                <label for="">Name</label>
                                                <input type="text" class="form-control" v-model="form.name" placeholder="Insert name" required/>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="">Email</label>
                                                <input type="email" class="form-control" v-model="form.email" placeholder="Insert email" required/>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="">Job</label>
                                                <input type="Text" class="form-control" v-model="form.job" placeholder="Insert job" required/>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="">Phone</label>
                                                <input type="number" class="form-control" v-model="form.phone" placeholder="Insert phone" required/>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="">Address</label>
                                                <textarea class="form-control" v-model="form.address" placeholder="Insert address" required></textarea>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="">Testimony</label>
                                                <textarea class="form-control" v-model="form.text" placeholder="Insert testimony" required></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 text-right">
                                        <button type="submit" class="btn btn-current">
                                        Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import FileUpload from 'vue-upload-component'

export default {
    components: {
        FileUpload,
        Cropper,
    },
    data(){
        return {
            edit: false,
            form : {
                name: '',
                photo:'',
                email: '',
                phone: '',
                job: '',
                address: '',
                text: '',
            },
            files:[],
            img: 'https://images.pexels.com/photos/226746/pexels-photo-226746.jpeg',
        }
    },
    methods: {
        async postTestimony() {
            // var data = this.form
            let data = new FormData();
            data.append('photo', this.form.photo);
            data.append('name', this.form.name);
            data.append('email', this.form.email);
            data.append('phone', this.form.phone);
            data.append('job', this.form.job);
            data.append('address', this.form.address);
            data.append('text', this.form.text);
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/events/testimony`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Testimony',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'EventTestimonyAdmin' })
            }).catch(err => {
                if (err.response.status == 422){
                    this.$swal({
                        toast: true,
                        title: 'Testimony',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async editSave() {
            this.edit = false
            let oldFile = this.files[0]
            const result = this.$refs.cropper.getResult();
            let binStr = atob(result.canvas.toDataURL(oldFile.type).split(',')[1])
            let arr = new Uint8Array(binStr.length)
            for (let i = 0; i < binStr.length; i++) {
                arr[i] = binStr.charCodeAt(i)
            }
            let file = new File([arr], oldFile.name, { type: oldFile.type })
            this.$refs.upload.update(oldFile.id, {
                file,
                type: file.type,
                size: file.size,
                active: true,
            })

            let data = new FormData();
            data.append('dir', 'events/testimoni');
            data.append('attachment', this.files[0].file);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.files[0].response = res.data
                this.form.photo = res.data.path
            }).catch(err => {
                console.log(err.response)
            })
        },
        alert(message) {
            alert(message)
        },
        inputFile(newFile, oldFile) {
            if (newFile && !oldFile) {
                this.$nextTick(function () {
                this.edit = true
                })
            }
            if (!newFile && oldFile) {
                this.edit = false
            }
        },
        inputFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                if (!/\.(jpg|png|jpeg)$/i.test(newFile.name)) {
                this.alert('Your choice is not a picture')
                return prevent()
                }
            }
            if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
                newFile.url = ''
                let URL = window.URL || window.webkitURL
                if (URL && URL.createObjectURL) {
                    newFile.url = URL.createObjectURL(newFile.file)
                }
            }
        },
    }
}
</script>

<style scoped>
.cropper {
  height: 600px;
  background: #DDD;
}
</style>